import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider  } from 'react-redux';
import {store} from "./store";
import { WagmiConfig, createClient } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { chain } from 'wagmi'

const coinbase = new CoinbaseWalletConnector({
  chains: [chain.mainnet],
  options: {
    appName: 'whatistime.art',
    jsonRpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/Dhrp0Nm-3p6ayc_2WMTygrq_CVwX0jsN',
  },
})

const client = createClient({
    connectors: [
        coinbase,
        new InjectedConnector(),
        new WalletConnectConnector({
          options: {
            qrcode: true,
          },
        }),
      ],
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <WagmiConfig client={client}>
            <App />
        </WagmiConfig>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
