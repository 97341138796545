import { Link } from 'react-router-dom';
import Button from './Button';

const ReturnToDashboard = () => {
    return(
        <Link to={"/"}>
            <Button>Return to Dashboard</Button>
        </Link>
    )
}

export default ReturnToDashboard;