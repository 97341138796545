import React from "react";
import {connect} from "react-redux";
import Time from "./Time";
import { formatEther } from 'ethers/lib/utils';
import { BigNumber } from '@ethersproject/bignumber/lib.esm/bignumber';
import { parseEther } from 'ethers/lib/utils';
const TimeAmountAndValue = (props) => {
    const timeValue = parseInt(process.env.REACT_APP_TIME_VALUE);

    const {    
        oldTimeRedeemed,
        haveRolledOver,
        rolledOverTime,

        oldTimeBalance,
        haveDiscountApplied,
        totalNewlyAcquiredTime
    } = props.main

    const oldTimeBalanceLeft = oldTimeBalance - oldTimeRedeemed;
    
    return (
        <div className={"flex flex-col items-center font-open font-light md:text-3xl text-xl"}>
            <h1>You currently have {oldTimeBalanceLeft.toFixed(2)} <Time/></h1>
            <h1><Time/> is presently valued at {formatEther(parseEther("1").div(1000).mul(timeValue))} ETH</h1>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        main: state.Main,
    }
}

export default connect(mapStateToProps)(TimeAmountAndValue);