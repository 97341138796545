const whitelist = {
  "0x090c984cc693b991d7cbaeddb43cb75f13fe5f2c": {
    "allowance": 1,
    "message": "0x842d10c45b58b732e7e9390192291dd6455c2e86e6e230401761f208fdb04564",
    "signature": "0xd72e426896469f350c87a028eb0cee4f8ecd7a05c9fb48905958e5ea5c1b2e0c73de13f3e42ebafa5943a09ca7c7d3cfc68da1409da9820a7a010eeabdb80ce01c"
  },
  "0x0ad338ea47c7eec21d9231ff8a26a12d5904294c": {
    "allowance": 1,
    "message": "0x28ba0f5192d843b4350bff36f075ead768a6b8da4d458ab77a09832cd2c4e50b",
    "signature": "0xdd7b0dacd5fdd09e4cb6493fb9540df010cdfb195595008f67dea53991603d175ab7883d751e5ee91df524ca5a3108bc68f935be349c78da2d811f40c8cf768e1c"
  },
  "0x0b7576a64a0f4b4924d55ed328ede4979446521b": {
    "allowance": 1,
    "message": "0x6af30964d2fb88a3f5b35c0418637b293d713aeeca93277f87df86013f9d8436",
    "signature": "0xd6638f1142e904e83c1edf72a5a24d3f23f71ae255f1d5aeb2844337effef51d5cc8f997f583ba06fb5a6bc76b0df90cbcb04d9b940a8e2098df25d654949cc91b"
  },
  "0x0d30164a064a3e87d1ebb7c53081bf5d205a41b4": {
    "allowance": 1,
    "message": "0x32d0be86f37774c64fd525054c3e1697a736dd04271506168e9e0ee1d47924ce",
    "signature": "0xb7e5ff5e76fe852b60649fa0df96070ee0f377c9b718a5cf5c0ffc70d1c2698f0a13f9938762cf7eae6d8ef9e0251a03a6b7c03ba2b8d50969dfff4b092964131b"
  },
  "0x13bdd0ccf328f74fe6d8bde4f2c6f4bc31b0ba1b": {
    "allowance": 1,
    "message": "0x02d783f9a653d21d93acf78e6c302371a77e415fb447e835f8b5841bbb902061",
    "signature": "0x80515c0ece71977d5772550b4170e888ad6e696424254a123661675c977206966ef24a42f7e11182ae0dbaba8725abf9c2d1ed3a7c0cf614938ea267beb03dc41b"
  },
  "0xef7af918ceccb53d6231365e5df25e8e14487a64": {
    "allowance": 1,
    "message": "0xbee921a73204f253e621a5f31469263539f78b9ae840cf13d92cedbdb4095509",
    "signature": "0x4ae7e84307b267f188d386657228fad9d0ac6d43804efb656344c47fb579feff588bba3267796eb07d4d192d0bf56710539b49880854758470f70530cd88de511c"
  },
  "0x2c7ba77e6f1d25ee8f4905d4cd6934e47ba19bca": {
    "allowance": 1,
    "message": "0x0e532a429e33bedc901649dc1a70eb1d8e7ae9171c66a3bf59f839254b24a8b6",
    "signature": "0x5b777c9e6732335700e03e74c1e8d730d6ee9b81b2caebe82b57c6c5488887463c70f14e79480de4c840867126ff8b082f232f4c8f0b9bf7ec10b8f4dfbb782e1c"
  },
  "0x2f4ad85154233feda792df976a6ef79052c313a5": {
    "allowance": 1,
    "message": "0xe9513de4b6de8df283954b815b3cd63d8618c1d6c6712ff50e50f4606006e601",
    "signature": "0xd21d3d7651493961d461960dcf3f69fdba4104d2de85adef11e1ebde69a680a31397f7975326a348387ab750671026712adee1c741e62fe29089aae896c4ed111c"
  },
  "0x2ffc6ea4ed3a8ccb7d1990472a39d00109b198c3": {
    "allowance": 1,
    "message": "0xb08d6458c98861f34b2f8703e23f3f9956da42e6f794c68065c0bb03993418bd",
    "signature": "0x5b82db0b2f79870297007bda0c1145fcfc25f567f8446882f9b56ecbb9684cd93bf3e9348fada4ebee812b6a5b4b9c1ee684ec171da2aaeac8b100e8af1bd9381b"
  },
  "0x34b7166295df086ad6c5a74340b61f3f3d34d580": {
    "allowance": 1,
    "message": "0xf13661375d0a8073b920ed66caa13252282bd6bb4b867bbf98ed403419fea623",
    "signature": "0xaf22a4472ab10bfdd51bff65b1fba8726dd8e8f6356562d6e575b452355523887b532725353fcc173cd5a094132718b7150b91373fed568706ed1146e8f737091c"
  },
  "0x3908248d09ef4949ed24f827379f5a26275bccf0": {
    "allowance": 1,
    "message": "0x74db33295ca2c248384a18c6e0dba619d3c6e966e0ad1b72e4339dd016d9740a",
    "signature": "0x57abe7fcab1adaacbf3f1117651f709ee4e72923e5802265810389c9ecd50fc358541e16661953695493c59cd7b4eff88965ed06969c9e1afbba8f95003a797b1b"
  },
  "0x3a05fc249752f896af5fd8805af86212681edf8e": {
    "allowance": 1,
    "message": "0x38965e0611534e9a50876354a40373ea2479b1837c57d6c8a424eb6e04a13838",
    "signature": "0x98e78d15b85dee8b44e28ec761813cc43ad0adebb8fad91b07ea8329fba2b44012ef067a5c15298719a5d63ee80ffe5192210421e0ea28fbeea60938e19eb1e61c"
  },
  "0x41da82a03e3b0818ac58f6a38eb2e0b39cc6c1f9": {
    "allowance": 1,
    "message": "0x8aeb34b635c031c1558c4ad3e465fbf0052ea7eff615312580cd4c905413aed0",
    "signature": "0x2554cad0d8f887a7315c5d668ee33b89a5024f626f1664f99f08758fe7dac13d436aa3e8e89c027a18a73b5aec6d367370b21f66e1fda749e66ff0e41c2b6e651c"
  },
  "0x425c9d1396c60e3235b48b68a642fa9ee4e07d77": {
    "allowance": 1,
    "message": "0x80433c9b883d348cfe534fdbd401cb2ef6ca1aee0d919b93855f4bbeaf3530ae",
    "signature": "0xa22daf3d18cf73a4e7156babe8226be90a7382693a749a70b38dbc3fa9616dd77336015098f5232df8fd802a780586007fa664167bc5aced7066f41c498e915a1b"
  },
  "0x431b251725cbfcc7453a403eb824cbdf11aeeb09": {
    "allowance": 1,
    "message": "0x1e4eb9540b2de8f292ff5eafa9fad4eb1d71b1177f1a2b3b69583b3ecd6f104e",
    "signature": "0x903bce1a25abd41aca16cc03f4fa9a56bb398bc06088f843f6d6c8729861afc51c2cdc6cade41f69e2f501074f36927f924866afebe72b6a9abfdc6e261362021b"
  },
  "0x486b280ba11b49aefa4805c26d4cbfbf78a2655c": {
    "allowance": 1,
    "message": "0xd9030aa7a3dd915d8726c5ea4157b281ee4f6e06bb36d16ef6d332e595a59495",
    "signature": "0x9b876c52c377b2a7770be67db3f7fe2c3592e4b09cfe19a780bf926585ffe3357b54a88891db75b90dc6d798585a55c095a00e6f78a2e3df514c4e953d9605731c"
  },
  "0x48a983122ffcebad0d8164b1026d4e7fc4a79c07": {
    "allowance": 1,
    "message": "0xe174d3e30f016a10754e47a10c591886ab286c123c7a723f9bf93c031beff1c6",
    "signature": "0x1178c0e03102969067635aed9e005b1c3de37ed84643f90a40fc25e4d9e9bdf407ebfc0338d05ee7265120449fe8cb2fae7382423d72774ead76cefbc9a557651b"
  },
  "0x4978a0ad290b630af892f9fd069e04d92aff170c": {
    "allowance": 1,
    "message": "0x9ab80d19494926f1b771893c6ec4424f9523a44c37b0ff18ba1742a1894ec916",
    "signature": "0x50cac7d2b8d8ecb2241bddd2d1788039370134cf0aca838a95686165c381039a716f0c09c2a4c3fd75c767f90d5cae5d05d740b0236e3ce20af81118537e8da11c"
  },
  "0x4afbfb0a64bbe9837f78fb2b262cf105076715cf": {
    "allowance": 1,
    "message": "0x298822b9cfe68bef6c19462e8cc71dc0e925d7c13cde705bf09954d53cfe701d",
    "signature": "0x0e10b9c8e1a80c2be6210cd08bf5eff2e4270b359a98fb9d25d8ec702d90574f3a2ff877155be9fe4806e80e6910c6cf318b9ae6589266746cf8c8b68b899e891b"
  },
  "0x4ecfaf14539866122b7180078ebf3de3a5a33b9d": {
    "allowance": 1,
    "message": "0xf5296446413b5a9faf6428911261988e5911b00c428e5018f4a38ff0d1947e1f",
    "signature": "0xb5dbb0abfe2d385484b697ffbd24cd68c5413f080df6c86abb578a7302325dda65232ad8b0137a5a4a5eeee534d1e6a45ebcfac425a95ad704e2144907568b951b"
  },
  "0x5943205d163d37b3292500d14748fc28083e88df": {
    "allowance": 1,
    "message": "0xd378689e43c9c6d6398eb5f6f9702e77b76fb7571a30df0e02ae54739818c0be",
    "signature": "0x613acfc2e7fc8ce8dc0128aa5083c9901a55617f87415c66ac153177f91377bb4b52022d70c03e874ce7cc47714c3c14ebd50b26f34c0fa1466b608d9f5466f41c"
  },
  "0x5fbf93d92e237e93ab00f3289cd289ea806261a8": {
    "allowance": 1,
    "message": "0x8474cd2ed9fb0bce59e37765bcebec5ba6de62cc8b21fa2ccf6b5dda80eccf8c",
    "signature": "0x18a444147e797bee209612d4c4c090ce0dbb921002771e5283ba81323989d1e9091ee46c258ee02e5b2aa0243f7fd2affd3c925632694779b96b34dbab7eb2d31b"
  },
  "0x1BA0aB0657DbD97694cB93815866B64C2e21A7a3": {
    "allowance": 1,
    "message": "0x99f43e7751314632f186bb63ddc8b7608856d293ea0ad59dc62e350eabfcdf52",
    "signature": "0x84e864abb487fb440da58bc4f7ecec3bc8ae4ad20e9a3ab57e402a4f9221209848264a1e4b0c4fa61087d8a02fb4584f20b7a3539bc88d4dad734e24a5ce96fe1b"
  },
  "0x66c5aa1de6a0a39e946da516a53ceac730ce33b2": {
    "allowance": 1,
    "message": "0xf20ef816a28b489cab5675edddf563177164d11503e82a0cda12771b4ba55575",
    "signature": "0xf84c487490194ebe9ca56d13fee5a46542ca12935dbcf44ee876b1ab795fa44c47cd7b89ecf062d1dcd6c7036e4fbce031e9d4bfedd8362a9c1d1b16b05383801b"
  },
  "0x6745d6a3590930611449e6d522c2bff9a912e873": {
    "allowance": 1,
    "message": "0x7ada3d07e35c3b331027d8a22d552cc0f73cebe995cb6c6d77bf71a5369704e3",
    "signature": "0x147a6f8fda1d063f10b29586ba31c3ee9ace23f1c77f12cd5e6ee93f18d7b9821d699833085e8e59af9ea433059dd0af5b20b724773f425979efa830a9a71ed21b"
  },
  "0x78afc7f736b513effa01e4fb2611f9fbac05a2b6": {
    "allowance": 1,
    "message": "0x0cc79c6e38759b5ae029057646afeaf03fa6ea99943c39ae9ef5a6fd2e8378cb",
    "signature": "0x40b980855781f18b017329fb68f06ad0dab4b408ccf2b2c17dc383e1943a57e47ce7da090e2655bfd38194e7be896c949a3d8d7ba9247be6bbf464cb566a9bf51c"
  },
  "0x7bd98bfb54b851abf84a272e87be0053ea6bda45": {
    "allowance": 1,
    "message": "0x231c95936f6028b70ed3718bea41b8d607db1a8e816cc88b3cafa8ad871e17da",
    "signature": "0x56ea5ac221510fa83140ec4f68eaf560029eaef8910c0f7e591c5c96853ed1140e97114b0e0d34d1ca97eb0b3f35641453229437459518acee4a156e93e43b9c1b"
  },
  "0x7cabb73f5b840b245ec2528751445da1f6dd7eee": {
    "allowance": 1,
    "message": "0xd1bb680363555409069e12768e703c481b7ba28a546206a4d57138e3a57f0b06",
    "signature": "0x97f6e948f3dbe8debdc6dddc085d172ef59041654646313984e48e6b56ea8dd31f8b1b23c22c4816c048afe7183ee5e51fe9d8fc1e315305ace8c0e2540ceab71b"
  },
  "0x7cd74f426caa3492fcaabfa8db9db2bf4eb5cb54": {
    "allowance": 1,
    "message": "0x553ca69ae2158cb3615e43370526e30eb7ecae5a6a57e41b7b20dbabfeec117c",
    "signature": "0xc2861b24526603d6adc687191bf48b850da4860fdad248547f6b48e6817717587223e2ec504e7eca474568fd758d65f5912ac0fe5ca28a2d3b0f44c8af930b201b"
  },
  "0x80212d1d03cb6a12b61cf5e98ca98d30c293127c": {
    "allowance": 1,
    "message": "0xc8ec7a5656c5b1253fc80605986caae5fa6f4a4dc080c476a35a8d8f904a384e",
    "signature": "0x54e8272a901a96cac4cfb126a1a20a25d2a9155d62ab95be8194c3a7770f17814fc337365b95120e44c47ae616a4e7ff6689c3ec06c629421e2c0a81298ec3a01b"
  },
  "0x80312d78486bfd0be7d6542c4ad300f742a7f3a6": {
    "allowance": 1,
    "message": "0xe18347d44ec86708cc6b08c4ddbfa0fbf65f022cc8def663101e2ea52e29b7ac",
    "signature": "0x01f8e59fc364d117b8746f4516c17236fa184dda9a1a8133f379b037563af9fc17b5657b687dab968ac7160478a99c92eea3c2db6fa0137cced7d245a1b9a5061b"
  },
  "0x8252dd05a6c4b70b67819ecc90222b2cfaac816c": {
    "allowance": 1,
    "message": "0xc4934e2d543e3dd5b34518cb15510372c29f8dad6fdd77c3461364f64e466beb",
    "signature": "0x1dc81b3e2b12b9dc61392932b35d8aa4c67aa1e6ddb05ecc1c5c65e624a3671a2005aea8ad430ca518c81cfacd7e89f48efa5e81c1886074c24744322a53ae7f1b"
  },
  "0x832d0917b83cc41d5c049493ab6fc34ff6074af0": {
    "allowance": 1,
    "message": "0x8704c6673cd9e3a229dd1799ae65f1bc034c31ff761e5eae5b0a42eaabf62ae0",
    "signature": "0x3dc7fb492e24706a6a7b1a438713d7b2ad572233710e986dc36c777a0d12e8fc6e0d9ffaa60507e96d1e12c5afa9cf6bf35b2796fe2a399b20f7a0502aa5e1191c"
  },
  "0x87d580a0b983b26cd4ce3c191e807f596f3793bf": {
    "allowance": 1,
    "message": "0x4ae66c8c5f6b4812b58889697dc3ad15ecfbaed704a11a2a21d62869faa9401c",
    "signature": "0x399d7298ffa8aee16cb17f8e7f18ac3d26f513fa3edaab314111a3d27e8636880aea8aebec85adfc73c1a46799493c3bff2e00eb6adda241026c7e3189c234651c"
  },
  "0x891b3c261ea6bea0b96b32383120b2dad7f73151": {
    "allowance": 1,
    "message": "0x1a403a31567dd6074292939be155a7af56cc17cbf0675c1f6e94899f17fc03cb",
    "signature": "0x6d7ed902172be729c6fc5bb55fcdbcd57b2c6e0ed5c2411b1cb3dad91d373bb018e1f00bdaa4bcaf600070ce48fb0227cbfd077ba123fbed85c0f4b373e198c61b"
  },
  "0x8b3ce7fe3c0269c9cea22f42567259f477650a84": {
    "allowance": 1,
    "message": "0x66df41f95fbccc7b93d836e82fcabd205dd4005dca280e9f613cb2864a877783",
    "signature": "0x71b94e62b87012dea4e2b99e9aa2769440ef2a7ed0027e815dfee1a64b282a3b7cc59f8158587059db611e542e386d87e56e44e142902e3fe062d1a29427ea1f1c"
  },
  "0x977c585acb0400ec97bc758ebe6c8e697c3464ec": {
    "allowance": 1,
    "message": "0x64e96e900a672aacb4d47b2ae859fdaedea1ca3b4fcc3f7eff9d1561c435b9bc",
    "signature": "0x5f8302c7b5bf0050a9f8825894d955df043e1e5e3346fb9df430a33b870f48a4798566232cd9ad987192972f61a6472ce9263affd9c195fecf91110d4b55a5571c"
  },
  "0x9d4d2eca4b77b730251320577fe31bebf9a389e4": {
    "allowance": 1,
    "message": "0x8e09aa51a1a9a2831fa381f37ec3ee263da82a3fb27d1ee9539900c197b6ecea",
    "signature": "0x7962b9c0982d066620bb5b56d431247484980cc4d778416d300f05ab414a2bc64011bf6f8bd1224746eaa135c2784d01fd0b77ad8d8a06eea7cce557f29dbe1f1b"
  },
  "0x9e9cd841844c804a5a389c2b6ff4224178599aee": {
    "allowance": 1,
    "message": "0xaadad41d1e269725de9c00a1f6981d04ab374c26ae5c45869af8166976dbec9a",
    "signature": "0xc02716e28bd5ca853e75a29f09a8874d0901ac378cf683085c3e838db0be2ec83edc7b3629e31a7990e7d815686920c502262c06b97ef543f054a9c8ab8a17951b"
  },
  "0x9f5544badf41b6f9aeb9b8aea60a4ef0735d9f6e": {
    "allowance": 1,
    "message": "0x174752762a0f4b2e0447344d97d9843303974de0a9dd4e5d595fe72a53658f16",
    "signature": "0xe4491bab624909a18dc857aa6e5cd72669e99f008437f254817f130b6eb7fe7a40a64a036b78221445360665d74f7f56bdab9a3a867495729b1fe722f481fa5e1b"
  },
  "0xa00f3e463d2709c291c48c2dfb75fc2acefbe284": {
    "allowance": 1,
    "message": "0xf725ff0dd3302ec9150500c26820a6155d3e02f1ae8406337d7bde2a47a8e455",
    "signature": "0x70193ed47078a19002f77427b5c56958f1150f6a1a43f6786e13b32b91c846a457b1a1d4098328b7ebd253f17c367f67c5ed1b4f4926383a77a37da86570f9b71b"
  },
  "0xa1d6ae0d6d7c539fda043bec4eb8c91201071223": {
    "allowance": 1,
    "message": "0x9196a6b6fecf088d067825cf0ee4634c67cdd04c6db2431f811f75aa00661396",
    "signature": "0x74c300aa888287397a0683639c6c93b7efb22784d736e22b02eb2be205bf49d000d1bfca850614d469a47751cd11d0fb314f613d99a377e55c23dcb254da86691b"
  },
  "0xa4a1b6efb288060c92ab1e8d3e1e9a118f1fd8df": {
    "allowance": 1,
    "message": "0x4dea4ddfa0e591d010cb7167aabb6ebfad1030ff44257b15fa96215148fada54",
    "signature": "0x7d1d2ea61417d519210f8d12c5ed752c0b099da3ebcf34052fecdfb57636803f65db4e77a3078b56db8cb10a978a7ebafc0ae08996c9e7dbeeb8c5ca44012afb1c"
  },
  "0xa4e107af951156c7acc6b4dd714d14f444342a12": {
    "allowance": 1,
    "message": "0x061e0015b7ea73107cb3c4dc6b8fd067ae06e9483e99b2c75b5d082aade89be1",
    "signature": "0xe9635c20af1f892e38e5f698792c8b477785e443fb319527ec36da2feb67e4aa08861fc0b9106e07fef4e406eb846b4a795fe912de778c7e8808e6f242dd7c361b"
  },
  "0xa74cee4ca7066e03183b5049ba51c0f5428e2315": {
    "allowance": 1,
    "message": "0xf0e66b28366fdf33ee7c2aa967549123a9d2aab5c9b3c128d2f2787b4408d12e",
    "signature": "0x7a5398f2c78af5d09f11dbcdeeabac096ab3b797ac48934ffb4fd224c96524ab020c07b9126c44d8c1f6110c0440c17e367512e4a88cfd8b87b523ecdff42d161c"
  },
  "0xa7ef08585b7188bcc140053b039933620bf7e04f": {
    "allowance": 1,
    "message": "0x3cf89f8e5cd4f0a7edbedc634023c57cbf8d73fa4428cfd02523d2b85c97ff13",
    "signature": "0x3bcfcef2da8ff780d0b788493aaceaf265475a0fdd50145226e59d644963e72547919c27c567ba7c61942f977d39c2400f2c2d599ec8ac4d3bf13d44f8091bf51b"
  },
  "0xa9b1bdb7ef96a6d9ed06baff6887463e18ae1b93": {
    "allowance": 1,
    "message": "0x07773af146ed28c38af6fca914f414904fc1b90413ffdacdc5a7d47f704b8948",
    "signature": "0x3c711a2b59fc43c6b764ccfec51d739b3c6917f1c859b945103eef507d03da7c33cbe2772be72bcd6a28deeaf9af9920d151dce5b91ff964f58e64aa3273786d1c"
  },
  "0xaa15a96fba6c84737e632a93140d9f549f55338f": {
    "allowance": 1,
    "message": "0x92452793070a11b8018a20f4aed4dbf68c0a17685268551c5c29ca63d4d37bdd",
    "signature": "0x268c01e39d5dde4038c4e064aad50f92179b256ed3ed898e48d0b829eba5c71679f6ee98f7cc79128684b9f0ae9cb9010e415d0bd48669ee5e4581796084ddf21b"
  },
  "0xafc4a7cf5dcb84a5621dd412a01d151cf4aec142": {
    "allowance": 1,
    "message": "0x5da11d23b02b4d7d862f5a74f3aa096d1d04d9e30eff91eb93a047045e2eff15",
    "signature": "0x568c989033de8d9383bd045ebce58c7631b024ddeaa20ed36cca472276095b575bb49d0661b21aa85bba410a827bf51a0e35e81c99a1cea0f1decc131e9f6c4c1b"
  },
  "0xb08f8ec35d78dc72531ed61c759009024642dae6": {
    "allowance": 1,
    "message": "0x5f793b66b4397ef429b254dd03b7a3308a4f48aef5c86aabc285951be85bc8c4",
    "signature": "0x8e4dc4c4518ab067272bfff1e1bd7073f9d839b6ce6d454703e33bbce8abf2737c87190640e0aa058b5e7cd0c3d2379d92ed1be762168b7eba0919e602be3e831b"
  },
  "0xb168fbeff4849c1a69537cc857c1d3d357b984f1": {
    "allowance": 1,
    "message": "0xf0545a932b221a3b4c51781188fedc09b9d69a21fce5857a3f99f96d6fbb4505",
    "signature": "0x4e070cf9ee27284725e24bb2f44353d31fa33a4acf357c5d0201d1b224aa13c30062c87d05864cc5709ef62e48601671a722e8f86b130d86881bb6187687dd7e1b"
  },
  "0xb281e92076aeb49f6be7cdaa4281640873c84d02": {
    "allowance": 1,
    "message": "0x647a91f6a19dd47c04d882e73b307d32c817225f51773dd2458767adf8e60050",
    "signature": "0xe4a8a03c1fc0ad782499c7a5b65dbdd953497ddb49b0016f26ba8000a899588b276f06309b9effa77d645cdc4b0704a69852aae41199363c8867d00a7c6a80dc1b"
  },
  "0xb394627bb2c594e729d68b12e5f33125e107ad62": {
    "allowance": 1,
    "message": "0x41a182d711f4c04dc0f447718d35d19fcdb37130444b2f4f233aa18b7ea8beb5",
    "signature": "0x64143e8387ba7cafa1c5a3450fb1fb71f22187b3d2a92e00e1949c591a82d2190d004038c7ebae9f48eac1f2aa4a49f98c23e9292179148f155ebd54dbec7c471c"
  },
  "0xb616e1127ae0f3148eb58262b42863aa95c03317": {
    "allowance": 1,
    "message": "0xe13d0b74030c785e7dc0f991208ad3351267ddd2854a06975a1c24466043cd52",
    "signature": "0x672f29d508b3df8f5493e4270cafcbf5d59b4732c3e3933ade7fa9e50ea722fe3daafb582a75c7761fc928ca15e9cd36fcda96cda83a1be00910b8c1331bd4901c"
  },
  "0xb8b8ce5e3d0ad5c0169e130c64048b6d3cfcd343": {
    "allowance": 1,
    "message": "0xb3320e89852c3cb4633fca9b32446da0368e4b66c90d9fcce9b915911258b03a",
    "signature": "0x91c5bd6d53da358d61017a65dfd8fd6c770d9b5955b7fbe8a5da5ed3467e8479692f83564754eab6cb35b90f82150bb2671d84707fd48809f0005123abf1d0dd1c"
  },
  "0xbc608488f056d571d8cab74093c6c6dfc7b537ea": {
    "allowance": 1,
    "message": "0xc577f8f993af49c367ba96371b9bbb6ff78abfbacc0251cc3da6946349cc19c8",
    "signature": "0x2a5e90249bb1a8c94f45d191dae67e3b48300e7e08759c5eb4518b9127de18f47772cc0396a50b8023904729b9cc387e79c016b8d087068f1613a0358a6bb87c1b"
  },
  "0xbcb71a888245913391f6f2f7ff202a2f00bced7b": {
    "allowance": 1,
    "message": "0xe921f1647ce8f31c5319dcb60ca51759b522b2ef46e2cc49a64230298597dad5",
    "signature": "0x0a2ccaf470002702798e325227f99cc8089cac3dd623d2373f1144b0ee14557f644b23bdda9be4a499e582cf4b526e2ba04d626c33cd729edcb58559fdfd00a91c"
  },
  "0xbd1fac0925318f098ea7696a341e97e7dc55f90d": {
    "allowance": 1,
    "message": "0xaaf9b18abd21a857765dd7060d00acd9a863b733a1fd807f8e95df69143fd2e0",
    "signature": "0x3ff7ccbbd59f9275bc28c8e8e75db2d3b6e92b29d52fcb6a0e4299f5f3c018dd6193709faf3bdacce9675cf52b52d6219749d2c5b53f182bdc4fd282c5e533ac1c"
  },
  "0xbd6e8438cf2e5830aa4d7289eda34cb6ff6471e3": {
    "allowance": 1,
    "message": "0x503ca58cecca6edac84f087cf393540574d79001e0c29975be0545e49203e601",
    "signature": "0xe7e9f03ca5731dfdbff42d5764374645de445154b6e19cb9e9f352081308e4395018f0b9e7bac7d643702cc083746fe86cc57c869f50e8b1e36908c7a8d0eb8d1b"
  },
  "0xc52818309f76ef198ce046ea68b6fb4e89febc58": {
    "allowance": 1,
    "message": "0xf32b9b28b203ff45aae8d264e5a17a968b600249543e3d6b699eeb9eec640ed2",
    "signature": "0x5178e7ecde11d03e0cc1baaaedd5d87754b7826086196ca5d20abc380209c01847f176d146f10b0d1c8995f4fae25aaf23776633f76be4aab56a6849a170b7641c"
  },
  "0xc77a38504bc507990c4782031abfe847113970a6": {
    "allowance": 1,
    "message": "0xf6eafc0783f2a8b8893b17101e7de22c2ec4140dbddcd8da45e6bbd2bd55028c",
    "signature": "0x5086c2a9a2aaa29f63ec1a1786ca3253a6bfe6a676b7ab9a59b2a9f9f982e01f3f696a3987f785cae8ec155e56709bb40c7c8854a7524f948a41542b3edf80401c"
  },
  "0xc888c19a101979e0c29274895a86f0c3bab6cf7b": {
    "allowance": 1,
    "message": "0xa24514cc510684c077d9c72ed489ac3220b4f05ce9e10bb43a27c1b359e2bb58",
    "signature": "0x3359911896d5e35217220091812d0881270ac870e399a70334201758dd1292b02b0c6e79c8ab4f0aa2788adf78cf1ec08947866284c749e74a1f91deec253ead1b"
  },
  "0xcc55e546a297ddf6cc56301856ebe054dc513c4e": {
    "allowance": 1,
    "message": "0x202f738380350c6ed70fe1bfa70314edf62fed79e87714e365ae00596edf279f",
    "signature": "0x7381622ef1f9fa4722a636adef2592a287f99c411e3c0e1bbd3d0b8833b5e0834878b0ab5bec115927c376b827ae6bc312bcb349d1801fda8a9fa4980c2ac3d21b"
  },
  "0xcd486efddbca4d4ac9504f4272a7148376b36e94": {
    "allowance": 1,
    "message": "0x98690a9ca78724afcb88f7e34cbb1d67591fa6a72e4959ba7be4e526b48b52d8",
    "signature": "0xf98138244976e17bc7d16cbfbb164f8a3aa8ea8ac814fa29391d6847f4d5f5192386da390e0bdd5264c2b7f775d3a8e81160b06461f7e37e3750bd3b7474eba21b"
  },
  "0xcf78dbf6943d23dcd3aabe0bdff6ddac7be89a41": {
    "allowance": 1,
    "message": "0xffe5a33f5ef4bb0cf932f38616e5df0360528fb71c7384808a5dd36768323a6b",
    "signature": "0x63dde1cfdbe2797ff8b98283bef783c4b8781d437f1fc916d8e82b015e49ea4a12430ff6eed7d634a6f5fa2d0809dc6c4ef895984913b28b693e5576dbc42aab1c"
  },
  "0xd0725dfbac6feb35491382536f44e6b2f8062a6c": {
    "allowance": 1,
    "message": "0xa9d3ab5d9ff5ac92065436c695548a2f42b0533ba036cec69f2ae9d47bbf3a8f",
    "signature": "0x851381701815e8ed261d608c6fb315b7759241e62f056eea8776d1312ad14115538f6f6babeebc2ddc54164043df82ad391d12a04a0d036ab97d20a63f2faf291c"
  },
  "0xd222ebdd78afcbef52995c090ae846f351493cf7": {
    "allowance": 1,
    "message": "0x9dc33c5e34b85dc7d327f586b5900fe1233d9c818695bb00653c58a778738bc0",
    "signature": "0x144d0e78ac92d25c240906d7b64ffeebb2a4ce23bcf3b5d44c84c97815adb401292419aeb97322f8e4573e8148b3143f268070af9aaefb411bde9580aaf8d60f1b"
  },
  "0xd25a091d69d7adcd1830bef90c70dc5765d98db0": {
    "allowance": 1,
    "message": "0x522ca5aa2c97f06d3c1972f2983be31e439d2b2bf35da108a9b509edf1195f74",
    "signature": "0x12322e81bdd6870a0ac7cefe5b989660aae35a6147d00b832e4bc6df2806e0371a1bca5e350ff95ada3081b1053ee2af3e3bf4eaf317b8211b88b7609efa2b5b1c"
  },
  "0xd323e4b040111b3feba91091290685cf0be3080a": {
    "allowance": 1,
    "message": "0x3bdd2ffbd5b6ffb70d2ef91f983690645a819ca006aece4072b47ce232b16a4c",
    "signature": "0xad708b483bb6308fd4a155f21af4488849bb7f02d011e20b2d53d1faafdaa1591b91ed90f630a6265f3aa4ce57a2d4449b8c9931f962dcf5f398961a4cf177e11b"
  },
  "0xd514088abe24f9198a2cddd04a964e2f5c5e1789": {
    "allowance": 1,
    "message": "0xb64ebc1be93a5fe2aa91e9d339a9e748b88ffcbde2c2e4f41cb6fb3741b44b09",
    "signature": "0x1e1d090bd08c8073cd52a1cabc307ad39d4148ff147052ee47c30de0cafc39333f2ca2d53a11b1289a52268300ac00920f739e460ed78d909850ab4fa97c1e9f1b"
  },
  "0xd6ce754f2d3089a9b8de063a976d74a677902ef4": {
    "allowance": 1,
    "message": "0x1757f3b75a03417a6e6c6aad501abf3872cb299d1fbb25c5193eb77fb33e37dc",
    "signature": "0x5fce3596e1064b7a8b145be84387ec710a7896b1af8ebecea5cf5b400b366c486892d08b97f4083e3c2b7a7a492408d21e87ba93373a2b4bfb2919fa8e64ca5b1b"
  },
  "0xdac86e3ecdf4dc8535dc036e166a89f34a62f278": {
    "allowance": 1,
    "message": "0xa6a41699ad9bedb7ded8f858859dd7445e704074e2b2a3c5a78d800ee6d47805",
    "signature": "0x3b2be87befe04eeca52041e4e566e0fecd8d6ad174baeda84c8b6379952531bc49621ac9e8a29eea65a0434866bb2d2e80e52b40dde5ed64640b94e56f3d9fd51b"
  },
  "0xdd6b1505ce6a473eac9eb2f2c5767f351a99f40b": {
    "allowance": 1,
    "message": "0x420b726eda8f07a4df19932a947ecdc3016e311c644684739f7a22b40c8ffb27",
    "signature": "0x537724ca099e176387e191839ba8dcb60fbc0ff8f87ee8fa9a3dff41f9d0e6f526c89018c977ce093415b63bc241a73da66091e14e2fe0a3c6f5dc129691d91d1b"
  },
  "0xde01bcd156dfcb69a095ef36132495d21e4a7a99": {
    "allowance": 1,
    "message": "0xe00ec172a20b71b2ed080415e7342f1b534c2a72d4c13ee50ba35cdea4771d5d",
    "signature": "0x16b755936ac6c4d768403bb13e4220d7bf95193b7420dde05808b5dafc6cfd8b1958ac4368fa1dc9fe0c1c61371bd04712735fcce0ae58587c838c4a344e882a1c"
  },
  "0xdeaba2b3c6831522c500fb2648455cdf7c7e4eb3": {
    "allowance": 1,
    "message": "0xcf736b5de4c3cd60e87482837751bc7d288f75c8eee841a3c7a6722d7d91ff29",
    "signature": "0x8d945df6ea31e78e689c8b5b8ce3a9602388636491b17f2e2b4d1ac9d61aaba039d7603a5b4e77bf12d8a075bd61942343a2875f73282a86c6b52468967303d21c"
  },
  "0xe40636e5b376a9d993ed664b5833201a63e9279f": {
    "allowance": 1,
    "message": "0xf128ae9dce77618524b8b7a858a8802032bbe8037c68ca84ed9931473ae785a8",
    "signature": "0x7bc0672fdd682b6250abfc6ee16e1292af379ae425be04215f352b1f41ebea7d388102c827b815282e4fe612b865bfc136bdd7fe5278406a139ba2dede96fbe81b"
  },
  "0xe5344e8e5a8440f1f72f7b2f35e68631ce72c749": {
    "allowance": 1,
    "message": "0x930f78ad2a3f6bd1bb613dc746cd1545c56f941186f62c6d691b1023da346399",
    "signature": "0x5e0962a23e4fc478c74be97878d49c44622d526a8b7806ed063558e67c5b0bc618fd5a6b055072ea9d749ecd079454431bf834f85a1c428384a223c0a7fbf1941c"
  },
  "0xef355176ef1a8d63fc781b138bd3c0ff8d2e9d35": {
    "allowance": 1,
    "message": "0x16e55910f613a48df339a33c79b803b0fcb38ed9a2ff47fbdc22f7e274cbfdf5",
    "signature": "0xce97312216892e381ad595d949dbf9b0894756565ac8d3ba45af6773bff39eb051ba3618c6612c105c4ecd1309de6480bd4e63342bc595af167a37e4a1e981021b"
  },
  "0xf273658ee07a596db1ede0a32cb845fca773200f": {
    "allowance": 1,
    "message": "0xfb64b370e781a71153a66eb882217c9168934b06361cb38b861dbd536876270a",
    "signature": "0xf7ad01642e30f1077d8caefb1e790986c17d4ff4b6f2e40a30064d003245b1177aa5bb65366ba9bf4e3781555c135eecffdc8c86f8a33360187c6a481ecab2301b"
  },
  "0xf2b548394f5e8ee171209739d66675594be555e0": {
    "allowance": 1,
    "message": "0x89cdcc874521a547c862e36ee1adf696935f63f6b0c993a96d8625144079f484",
    "signature": "0xe3c7f5dc953c943ee55d47eecd306b81ae5efaeaf91f44967144fe0e397b0d026ccaf21a82a8385ec2dcd4353a9aee3a0e54d7481ad6b064b8e5a239faaafcbd1b"
  },
  "0xf99b11016b4e9ab00226446af3715610359fa8cd": {
    "allowance": 1,
    "message": "0x1ad78bbcc6d6ebb4c4433ffd233c4883e8f6e768f912f9bf8a19556060021220",
    "signature": "0x2e5608b40df8f9b637dc9c13033ab75a01c96f4fc7707154ba6f2882af97b6b457ec77a9f1851dae5161f4f2cd5d974222859ad6e84d080c5afefd4df7cdb9cd1c"
  },
  "0xfae635d34b9ee0ddff36764acd1960b4de01f4a8": {
    "allowance": 1,
    "message": "0xa71642745ca9367a73f25097c5c6329689f5e02f91f38dca016cfe1374c4e8e3",
    "signature": "0x14b7e0a58d1eb982cb4b9fa42fad383fb239ee66aa526fee74ec8b3d651dd64f6d8c711b836eb20a58fb02a00f36446a4fa9e74340518516a36cb0de63fc26c51b"
  },
  "0xfc7cebeaf84a770add37320546613d28a024a3c2": {
    "allowance": 1,
    "message": "0x4eee5735572c8d5e1e55a44439e0d2ea1081b7cb93b7377bb1d92f8b11836278",
    "signature": "0xdea63b7d83436a976c4b56c1be3c1a4ccf800be0b01bacecde0c8da1debe84dc6fcd9243975b031aaccabea899180107dcc2ced477f452c242fb76f1e580e66c1b"
  },
  "0xfe4f54330ad4bf9d6cfa48c472e962f5a4912572": {
    "allowance": 1,
    "message": "0xf88b5a527a50765e8f6bfd571139c6fbd66a346259c42ce6492086921a042eb9",
    "signature": "0x6d13340406d5f13c0fa90443bb2ad3ec4951991efed10062315582f57f72ec6a0febc957d884785fa9f01ed77f6ed720ad4f0499740c983d083c2fa67be9d7bb1c"
  },
  "0xfea8cd172569b06341c5a0a79b9cad8cd62fe23f": {
    "allowance": 1,
    "message": "0xbb6930dce0277181d4ac8cc80d3cd25fbbd0cd0de786871767ed76df35525e6c",
    "signature": "0xfc851645f11d0c77e05bd7ad10e1a6e00fbfe4277942413d83d74aa2dfb3a4db1825d7db7e4cc4113db311479efab8f3cfc159801bb2bb1840299862d4c9bc561b"
  },
  "0xe01a97fc4607a388a41626d5e039fdecbfc6acd9": {
    "allowance": 1,
    "message": "0x080dea8166f6314e74c814aeab107923ac0bbe228193665295363bf94e53c4f0",
    "signature": "0x96d7add4ce255d3c34f04d0c15d1cd07a7c2cc19d3141b726df90270f517f5c62744030d1a12c0aa91cfa06294f13b23f269e0aa8b030a6a0b3f476b2a4d74811b"
  },
  "0x1cb55daa5f5d41134423d80fd5e204027d119425": {
    "allowance": 1,
    "message": "0x81770576a2d41851f1b87e32724177b6036872d292c4ceb88d2567d5167d2d81",
    "signature": "0x27e9f07c0a841ab492c99e2ab4d688c0c732e95c3b64d12eafa1028040c4c6977ffb63f4b1764528eaf1a6a75a9f6cb01d5aa21a25428436ee174c20fd0dd97e1c"
  },
  "0x70804f88a50090770cbda783d52160e7e95d7822": {
    "allowance": 1,
    "message": "0xbbf599d7857ba922b132d2937de4cd5210fe4475c59f1bae5b59a6bd43293c10",
    "signature": "0xe047da875c4931adec7f07489916818ab8b048c1048c366fc5588ce02e7da7b659686cd23e54c9740e8dd5335a655476c800185dcf796df3feca25fd9dc754ee1b"
  },
  "0xdfe8beee223412f316baf2968b17527d6eba29f1": {
    "allowance": 1,
    "message": "0x945d2a187e458834ca7927e562a9eeefee614f1a755ed7bbf33260f61a393ad3",
    "signature": "0x0deb6bd8f39ca979b9c95d1ec9f83b80bc69e80e50b8fc4426e6c6a506cce1a425843fa70bc6a0a92a9fbf905f840cffdf82b668e31de33db7caefd9cfb351b31c"
  },
  "0x0b378a09bc3537ad191f2f62d7fad9d8b1614824": {
    "allowance": 1,
    "message": "0xffbbb526c31613758682a395198407f8733d647ef9da17c5b1fe1ec7a3071400",
    "signature": "0x286b0327269cafd9b0d901b302df85fd6b194c1fb7fae91da1ebf3adb8768dbc41b5364b19865f2f19ba8e373701269d448bf8977ff35fb78fefc38554015a671c"
  },
  "0xba4bb4e7102229d0f288f04bea3ea7e61c9c2ab6": {
    "allowance": 1,
    "message": "0x515a9c211b2f8f5c86668e41e85594155f04886512d8caee97d2cc85242d27ef",
    "signature": "0x9399b94f89f62cd3af8b3415e3b29993b661b859550ed857ac0bb10a1cd6e2c54f927da770b2109b629a7b6f16bc9c1001d014d437a4e49066d463b9422110bc1b"
  },
  "0xaa4d086908b0b372124410c484608aa4d40d1902": {
    "allowance": 1,
    "message": "0x68a6800d43ac2be5876c639fc5708c1c6c8559fadda9ee7924c13c89a3633222",
    "signature": "0x5c7924bf3211797d0c29d646b42fdc178eb8bb6141f7d17da008c7c44b36aec95df83e3181b335c82ed912a1e98d9dc6bea24922d21c0793016a076454637ebe1c"
  },
  "0x9339acfa818da6a0b2a196ef31d67acde156acb0": {
    "allowance": 1,
    "message": "0x20fca23277dacf9a9b5cad2c866a0795161f84e63d9a5b2eeee87fa8bae4a5e9",
    "signature": "0x0ea8713fe732d4fa8b0da44ba9b1b96baf477aab877737952ba1e1ffb880b2ce023b134ff5142f36bfb86408bf199e25273dfb155d0849a9043016c9e8c874ab1c"
  },
  "0xebf96b95e2d08651856ff82771876338ac289f06": {
    "allowance": 1,
    "message": "0xc7471007dfdcb6b5c52171fd2a6ba9303d1988cb4f49f760d54cd4faf70cc96f",
    "signature": "0x9f85513f31a3819567d507c00c1ed6455af5898ed79c2f5eed1de6c31bd16d80775d937ec33e7feac63bd8b24f841de6c410d729fdae1425472befdd1304efaa1c"
  },
  "0x763ac04c27eac03cc5fc9cfd5a9f8742a5e86a85": {
    "allowance": 1,
    "message": "0x8a804c29e7ff9284b485d81a5575977d47d1c70fb13784b1fbe27d190ce3b941",
    "signature": "0x9fa107de62c5dc80839c2adee13b28f6f9ea11188436ed7f73ea047cb29e0c0136f4f0f23786d14321f997e4e5fde78faddfd202ddb1c6d61d9d4f72fdadaedc1c"
  },
  "0x34ee0ff3f8264e87d7a3515c1cca3c077dc8c48a": {
    "allowance": 1,
    "message": "0x82ce81cf0fcb00e741aa2c7f762b3526d2d7ade60b62c593adca99dc4fc948b7",
    "signature": "0xa843097cffa61e0dd0d777e91936c4318b109d065be2296e8211d4491fe1abae74baebc64acaf87752c81f13a9409fc12cdf6e85c62c774ea8659d3587cfc7441b"
  },
  "0x11b8e03f1bb648ac17c6d7f3c8366bd735017950": {
    "allowance": 1,
    "message": "0x235a13d89ee0c2e43e8a2efb406c045f0dc400ae0e3f6a299b1e9c191f9a499c",
    "signature": "0xb923fb6f1ccd24d8cf63376f75535b9293e4dce74a0d46fa1d9e5c02ad43b54c33d4b351bb7e9e50d9e7e98b61646699411df839ccdf914470c3766fc8a914ec1b"
  },
  "0x57981e69ef332bfe83f354bf19157914e50c6ff3": {
    "allowance": 1,
    "message": "0x4a5ff75a3c89d4b53c9807032852b5fef602e914d1aaee9467c61e1d87705508",
    "signature": "0x9a00d97add987bcb7817e16d99fd5beb31e8e6900391f8d405aece21ae920eda395352b5f34d8e7f1987eddfd26b1c74c196d9471e572daf74f646befe2ec3dc1b"
  },
  "0x175afc69efe2e96fabbff3dc6b3c805a75cc3620": {
    "allowance": 1,
    "message": "0x2bc080675648f1d8c0ecb2f2c95e9abdf2cb2a90f704d3c2805ae1b476f897c3",
    "signature": "0x508a6ceb3a88e76e21922a91556e45233b82644b04e41fd3e1ae7e99900d91ec2468e99db895621b1b178da9a1a2eaab0d23afabdc0f95d0c6733544fdd720711b"
  },
  "0x6031d4e67f4e098f6663a3a6277bedbe529521af": {
    "allowance": 1,
    "message": "0x83999b914f7ad57cbb213c74b16cfd780b50d10b90a9e84ab08ab5a52d772cf4",
    "signature": "0x8ff37c4676addde1b3deb02a8a3660ca85f6ef31dedd52f077fbe48e7759f6d1280cdd00db2a9cbc730a80acb0d1d183c30bdea102fd21dfe9782630f06987fc1b"
  },
  "0x5c4fe960950ba0e09a72869c3d51fe70f07580e0": {
    "allowance": 1,
    "message": "0x7c31f18eb8271f71d93329aa3f4ed463b1027dfb4b5c3198beb754264e2cfc27",
    "signature": "0xf0da59d0b145bcc72d6f9240f2df7e7741611d174f9c29f06a60c992435a795c2a8bde380a6981a470e10f58a6c4199f723f5eb50d747586a43ee22755f15b001b"
  },
  "0x24fbadccd6684106e24065694ac87b0e98819235": {
    "allowance": 1,
    "message": "0x1d5ecd16ec2739782d784566b5351a10fb8a655a6f245ae01466f1f77632aade",
    "signature": "0x6e087a95be8743c729a9f0bb9902b577db4eb6602526508d3ad5954e9a2fc1a943f9b66006f0d55bf548598bf6cf5ade7d11db3601f7f9352c21fa489cf116c21b"
  },
  "0xb8427dcc33ec47332583879efd85c0de00583120": {
    "allowance": 1,
    "message": "0xa1231d17731cd8cf13462861cf20904752bdef1fd492a0df36a3ff609255c0b4",
    "signature": "0xd00197e1892a95ad6838e9f5690b387d78c2ae54da1c040a8d8a43ba50df1d8237d63d87ffbe241f903c956aebe050be200a71bb01a4d6bae0ba19c91695cc911c"
  },
  "0xb40e296d1eb1aa2ce72bf687fff1a77c9c42c9f1": {
    "allowance": 1,
    "message": "0xad136536dfaa26196e5b403bb04b2da7dfe28c6b412a7839429074f00d2f8975",
    "signature": "0xbf4246ffd69649905fffb6578cc92ce209613ae15908a362888c77ce19746a274e1a939e0a30b31fd527b1918002bce62418f4992df43d92d6f70b02fdffcb761b"
  },
  "0xe0b93aaa75212ef2839738e6fc139c36bdfef33e": {
    "allowance": 1,
    "message": "0x72160ce361bdd2e26901976a80c9afb9aa2a735d60cd7cc0e8c022e10c0d4868",
    "signature": "0xc4d2033d3adac0bbcb8f953b5f78bf0313e0b28ca4d94dfb7d22a129107fca5d6c40d3e8c52b1744826072b722873699f4c72a9cdeef03c07dca2a2908333de11b"
  },
  "0x072aaa8bfdd3fa84f0f505c83c0704250e9b90a6": {
    "allowance": 1,
    "message": "0x0ada77138419f726891dd7da93393643b3ba0a26731bce0bb0e240f5c6bcb9be",
    "signature": "0x3a484dcf49641d6e36337e39f8176a10e0f1c2ad7aacc85c3f3297011b3763e20f1875b0dd74bb93f39c1ef20747cf75605a6d5317bc4e6987f9da71d4fccc0b1b"
  },
  "0x38391462cbbbf297fdc1eadb9f4fb631d9dd716f": {
    "allowance": 1,
    "message": "0xe6caf4b292a555173cda21dd436848e1af6590066e8e55f199088b55f650bb43",
    "signature": "0xb83f2f3e441c17ed9c6deaf93725444693cc0c45e640abfd94476e479e5b8bf935df68969e1c936a3fe4a457db030646dc9863995e29b1ff53eaadacc2f5b6d71c"
  },
  "0xbb9702e27b7f207e9549c27e7b08e0c18e4b6d94": {
    "allowance": 1,
    "message": "0xc71b97371c86a0e9631a57effea366f192b3268271c2dde5b581e41e57f6cae5",
    "signature": "0x9d4039ca247e63faf227e9269a144300ad7897fcd9b9737292e83a2f0e8e442e30668ed4bfa3ec111d4297bbde34bd55fcb09c2f50f27ffc2578a0d9bb35f30d1c"
  },
  "0xe53cc07ae45e7675c6632a7c5092374820729797": {
    "allowance": 1,
    "message": "0xdcd197196beeeb5ca550e51f2471432dc0b398bd5ad7e00861b59e8347e841b2",
    "signature": "0x9413c52693e0935ffac8eb48a1cdd4d63764c2646b94b63c23ed19aae3ee1616781b46f389eca42a128933f3eca6fe0105d5e1a69869134872ed41110286d9211b"
  }
}
  
  export default whitelist;
