import { ChangeTimeDifference, SetTimeDifference } from "../actions";
import { connect } from "react-redux";
import { useState } from "react";
import { formatEther, parseEther } from "ethers/lib/utils";
import { BigNumber } from '@ethersproject/bignumber/lib.esm/bignumber';

const TimeInput = (props) => {
  const [userInput, setUserInput] = useState("0.0");

  const handleInputChange = (value) => {
    setUserInput(value);
    try {
      const float = parseFloat(value)
      if(float < props.min){
        value = props.min.toFixed(1);
        setUserInput(value);
      }
      if(float > props.max){
        value = props.max.toFixed(1);
        setUserInput(value);
      }

      const rounded = parseFloat(value).toFixed(2);

      const parsedTime = parseEther(rounded);

      props.SetTimeDifference(parsedTime.toString());
    } catch (e){
      console.log("Failed to parse");
      console.log(e)
    }
  };

  const handleChangeTimeDifference = (difference) => {
    try{
      const newValue = parseEther(userInput).add(parseEther(difference.toString()));
      handleInputChange(formatEther(newValue));
    }
    catch{

    }
  };

  return (
    <div className={"flex gap-x-4 items-end font-normal"}>
      <div
        onClick={() => handleChangeTimeDifference(-1)}
        className={
          "border-2 rounded-full md:w-20 md:h-20 w-12 h-12 flex items-center justify-center relative border-time-gold select-none cursor-pointer"
        }
      >
        <h1 className={"md:text-8xl text-5xl absolute md:-top-5 -top-2"}>-</h1>
      </div>
      <input
        onChange={(e) => handleInputChange(e.target.value)}
        value={userInput}
        className={
          "text-center text-3xl rounded-full outline-none border-2 border-time-gold bg-transparent md:w-56 w-40 md:h-20 h-12 font-open font-thin"
        }
      />
      <div
        onClick={() => handleChangeTimeDifference(1)}
        className={
          "border-2 rounded-full md:w-20 md:h-20 w-12 h-12 flex items-center justify-center relative border-time-gold select-none cursor-pointer"
        }
      >
        <h1 className={"md:text-7xl text-5xl absolute md:-top-[1px] -top-1"}>+</h1>
      </div>
    </div>
  );
};

TimeInput.defaultProps = {
  max: 10000,
  min: 0,
};

const mapStateToProps = (state) => {
  return {
    main: state.Main,
  };
};

export default connect(mapStateToProps, {
  SetTimeDifference,
  ChangeTimeDifference,
})(TimeInput);
