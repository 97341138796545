import {connect} from "react-redux"
import Button from "../Components/Button";
import axios from "axios";
import { useSignMessage, useAccount } from "wagmi";
import {SetAuthenticated} from "../actions";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Time from "../Components/Time";
import { GotBriefcase } from './../actions';

const timeApi = axios.create({
    baseURL: process.env.REACT_APP_TIME_API
})

const SignToVerify = (props) => {
    const { signMessageAsync } = useSignMessage()
    const account = useAccount();
    const [status, setStatus] = useState("");

    let navigate = useNavigate();

    const signIn = async () => {
        try{
            const nonceRes = await timeApi.post(`/api/authenticate`, {
                address: account.data.address.toLowerCase()
            })
            const {nonce} = nonceRes.data;

            if(nonce){
                const signature = await signMessageAsync({message:`${nonce}`})

                try{
                    const authTokenRes = await timeApi.post(`/api/verify`, {
                        address: account.data.address,
                        signature
                    })
    
                    const res = await timeApi.get(`/api/hasUserMinted?address=${account.data.address.toLowerCase()}`)
                    props.SetAuthenticated(account.data.address, authTokenRes.data.token)
                    
                    if(res.data === false){
                        
                        navigate("/mint")
                    }
                    else{
                        props.GotBriefcase();
                    }
                }
                catch{
                    console.log("Failed to retrieve authentication token")
                }
            }
            else{
                setStatus("Failed to retrieve nonce from server")
            }
        }
        catch (e){
            setStatus("Failed to authenticate")
            if(e.response){
                console.log(e.response.data.error)
                if(e.response.data.error === "User does not own a token - UNAUTHORIZED")
                {
                    navigate("/noTime");
                }
            }
        }
    }

    return(
        <div className={"flex flex-col font-open font-thin items-center gap-y-10"}>
            <h1 className={"md:text-4xl text-2xl"}>
                Please sign to enter the <Time/> vault
            </h1>
            <h1 className={"text-lg"}>
                {status}
            </h1>
            <Button onClick={signIn}>Sign message</Button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps, {SetAuthenticated, GotBriefcase})(SignToVerify);