import {connect} from "react-redux"
import { formatEther } from 'ethers/lib/utils';
import { BigNumber } from '@ethersproject/bignumber/lib.esm/bignumber';
import ReturnToDashboard from "../Components/ReturnToDashboard";

const Redeemed = (props) => {
    const timeValue = parseInt(process.env.REACT_APP_REDEEM_VALUE);
    
    const {    
        oldTimeRedeemed,
        oldTimeBalance,
    } = props.main


    const oldTimeLeft = oldTimeBalance - oldTimeRedeemed;


    return(
        <div className={"flex flex-col font-open font-thin items-center gap-y-6"}>
            <h1 className={"title md:text-4xl text-2xl text-center"}>
                {formatEther(props.main.timeDifference)} <span>Time</span> {formatEther(props.main.timeDifference).toString() === "1" ? "has" : "have"} been redeemed
            </h1>

            <div className={"flex flex-col items-center"}>
                <h1 className={"md:text-2xl text-xl text-center"}>
                    Your {formatEther(BigNumber.from(props.main.timeDifference).div(1000).mul(timeValue))} ETH will be sent on July 1st when the <span className="text-time-light">Genesis Time Capsule</span> ends
                </h1>
                <h1 className={"md:text-2xl text-xl text-center"}>
                    Your new <span className={"text-time-light"}>Time</span> balance is {oldTimeLeft.toFixed(2)}
                </h1>
            </div>
            <ReturnToDashboard/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps)(Redeemed);