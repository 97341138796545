import { connect } from 'react-redux';
import Button from './Button';
import discountTokenAbi from '../discountTokenAbi';
import { useContractWrite } from 'wagmi';
import { useEffect } from 'react';
import { DiscountRedeemed } from '../actions';

const BonusToken = (props) => {
    const {    
        ownsSouvenir,
        tokenIdsOwned,
        haveDiscountApplied
    } = props.main

    const { data, isError, isLoading, writeAsync } = useContractWrite(
        {
          addressOrName: process.env.REACT_APP_DISCOUNT_TOKEN_ADDRESS,
          contractInterface: discountTokenAbi,
        },
        'RedeemSouvenir',
        {
            args: [
                tokenIdsOwned?.[0]
            ]
        }
    )

    const redeemDiscountToken = async () => {
        await writeAsync();
        props.DiscountRedeemed();
    }
    
    return(
        <>
            {(ownsSouvenir && !haveDiscountApplied) && <Button onClick={redeemDiscountToken}>Apply Bonus Token</Button>}

            {(!ownsSouvenir && !haveDiscountApplied) && <Button onClick={() => window.open(`https://opensea.io/collection/souvenir-one`,'_blank')}>Acquire Bonus Token</Button>}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps, {DiscountRedeemed})(BonusToken)