import React, { useEffect } from "react";
import Button from "../Components/Button";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Time from "./../Components/Time";
import { formatEther } from 'ethers/lib/utils';
import TimeCalculation from './../Components/TimeCalculation';
import { RollOver as RollOverAction } from "../actions";
import { BigNumber } from '@ethersproject/bignumber/lib.esm/bignumber';
import { useSignMessage } from 'wagmi';

const RollOver = (props) => {

  const {    
    oldTimeRedeemed,
    haveRolledOver,
    rolledOverTime,

    oldTimeBalance,
    haveDiscountApplied,
    totalNewlyAcquiredTime
} = props.main
  const navigate = useNavigate();
  const { signMessageAsync } = useSignMessage()

  const oldTimeLeft = oldTimeBalance - oldTimeRedeemed;
  const newTimeAfterRollover = oldTimeLeft * 1.2;
  const rollOverBonus = newTimeAfterRollover * 0.05;
  const tokenBonus = haveDiscountApplied ? totalNewlyAcquiredTime * 0.05 - totalNewlyAcquiredTime : 0;
  const totalTime = totalNewlyAcquiredTime + newTimeAfterRollover + rollOverBonus + tokenBonus;

  const onRollOver = async () => {
    try{
        const rollOverMessage = `Roll over ${oldTimeLeft.toFixed(2)} Time and get ${totalTime.toFixed(2)} Time in Time Capsule 2.0`

        await signMessageAsync({message:rollOverMessage})

        props.RollOverAction(navigate);
    }
    catch (e){
        console.log(e)
    }
}

  return (
    <div className={"flex flex-col gap-y-12 items-center"}>
      <h1 className="text-4xl open-sans font-thin">
        Roll over <Time />
      </h1>

      <div className="flex flex-col divide-y gap-y-2 divide-time-gold text-2xl">
          <div className="flex flex-col text-center">
              <TimeCalculation hideAcquire={true}/>
          </div>  
      </div>

      <div className={"flex flex-col items-center gap-y-4"}>
        <Link to={"/rollover"}>
          <Button onClick={onRollOver}>Roll over</Button>
        </Link>
      </div>

      <h1 className={"text-5xl"} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    main: state.Main,
  };
};

export default connect(mapStateToProps, {RollOverAction})(RollOver);
