import {connect} from "react-redux";
import React, {useEffect} from "react";
import TimeInput from "../Components/TimeInput";
import Button from "../Components/Button";
import TimeAmountAndValue from "../Components/TimeAmountAndValue";
import {Link, useNavigate} from "react-router-dom";
import {RedeemTime, SetTimeDifference} from "../actions";
import {useSignMessage} from "wagmi";
import { formatEther } from 'ethers/lib/utils';
import { BigNumber } from '@ethersproject/bignumber/lib.esm/bignumber';
import { parseEther } from 'ethers/lib/utils';

const Redeem = (props) => {
    const {    
        oldTimeRedeemed,
        haveRolledOver,
        rolledOverTime,

        oldTimeBalance,
        haveDiscountApplied,
        totalNewlyAcquiredTime
    } = props.main

    const oldTimeBalanceLeft = oldTimeBalance - oldTimeRedeemed;

    const timeValue = parseInt(process.env.REACT_APP_TIME_VALUE);
    useEffect(() => {
        props.SetTimeDifference(0)
    }, [])

    const navigate = useNavigate();
    const {signMessageAsync} = useSignMessage()
    

    const onRedeem = async () => {
        try{
            const redeemMessage = `Redeem ${formatEther(props.main.timeDifference)} Time for ${formatEther(BigNumber.from(props.main.timeDifference).div(1000).mul(timeValue))} ETH`

            await signMessageAsync({message:redeemMessage})
    
            props.RedeemTime(navigate)
        }
        catch (e){
            console.log(e)
        }
    }

    return (
        <div className={"flex flex-col items-center gap-y-12"}>
            <TimeAmountAndValue/>
            <h1 className={"font-open md:text-2xl text-xl text-center font-thin"}>How much <span className={"text-time-light"}>Time</span> do you wish to redeem?</h1>
            <TimeInput max={oldTimeBalanceLeft} min={0}/>
            <Button onClick={onRedeem}>
                Redeem {formatEther(props.main.timeDifference)} <span className={"text-time-light"}>Time</span> for {formatEther(BigNumber.from(props.main.timeDifference).div(1000).mul(timeValue))} ETH
            </Button>

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps, {RedeemTime, SetTimeDifference})(Redeem);