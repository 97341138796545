import {DISCOUNT_REDEEMED, GOT_BRIEFCASE, CHANGE_TIME_DIFFERENCE, REDEEM_TIME, SET_AUTHENTICATED, SET_TIME_BALANCE, SET_TIME_DIFFERENCE, SET_USER} from "./types";

const DEFAULT_STATE = {
    timeDifference: "0",

    authenticated:false,
    connectedWallet:"",
    authToken:"",

    oldTimeRedeemed: 0,
    oldTimeBalance: 0,
    haveRolledOver: false,
    rolledOverTime: 0,
    haveDiscountApplied: false,
    totalNewlyAcquiredTime: 0,

    loadedUser: false,

    gotBriefcase: false,
}

const mainReducer = (state=DEFAULT_STATE, action) => {
    switch(action.type){
        case GOT_BRIEFCASE:
            return {...state, gotBriefcase: true}
        case SET_TIME_DIFFERENCE:
            return {...state, timeDifference: action.payload.time}
        case CHANGE_TIME_DIFFERENCE:
            if(typeof state.timeDifference === "string")
                state.timeDifference = 0;

            const newValue = state.timeDifference + action.payload.time;
            if(newValue >= 0)
                return {...state, timeDifference: state.timeDifference + action.payload.time}
            else
                return state;
        case SET_AUTHENTICATED:
            return {...state, ...action.payload}
        case SET_TIME_BALANCE:
            return {...state, timeBalance: action.payload.balance}
        case REDEEM_TIME:
            return {...state, timeBalance: action.payload.newBalance}
        case SET_USER: 
            const newState = {...state, ...action.payload};
            newState.loadedUser = true;
            return newState;
        case DISCOUNT_REDEEMED:
            return {...state, haveDiscountApplied: true}
        default:
            return state;
    }
}

export default mainReducer;