import React, {useEffect} from "react";
import Token from "../Components/Token";
import Button from "../Components/Button";
import {connect} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import TimeAmountAndValue from "../Components/TimeAmountAndValue";
import Time from "../Components/Time";
import {FetchUser} from "../actions";
import { formatEther } from 'ethers/lib/utils';
import TimeCalculation from "../Components/TimeCalculation";
import BonusToken from "../Components/BonusToken";

const ManageTime = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        props.FetchUser()
    }, [])

    const {    
        oldTimeRedeemed,
        haveRolledOver,
        rolledOverTime,

        oldTimeBalance,
        haveDiscountApplied,
        totalNewlyAcquiredTime,
        loadedUser
    } = props.main

    const rollOverBonus = rolledOverTime * 0.05;
    const tokenBonus = haveDiscountApplied ? totalNewlyAcquiredTime * 0.05 : 0;
    const totalTime = totalNewlyAcquiredTime + rolledOverTime + rollOverBonus + tokenBonus;

    const oldTimeBalanceLeft = oldTimeBalance - oldTimeRedeemed;
    const newUser = oldTimeBalanceLeft === 0 && totalNewlyAcquiredTime === 0 && !haveDiscountApplied;
    const oldTimeLeft = oldTimeBalance - oldTimeRedeemed;

    if(!props.main.gotBriefcase){
        return <h1>User does not have a briefcase</h1>
    }

    return (
        <div className={"flex flex-col gap-y-12 items-center font-open font-thin"}>

            {
                !loadedUser ?
                <h1 className="text-2xl">Loading <span className="text-time-light">Time Traveler</span>...</h1>
                :
                <div className="flex flex-col gap-y-10 items-center">
                    <h1 className="text-3xl">Current Time Balance</h1>
                    <Token size={"4xl"} >{totalTime.toFixed(2)} TIME</Token>
                    <a href={"https://www.whatistime.art"}>
                            <Button>Back to map</Button>
                    </a>
                    {/* <TimeCalculation/> */}
                    {/* <div className="flex gap-x-8">
                        <Link to={"/acquire"}>
                            <Button>Acquire more <Time/></Button>
                        </Link>
                        <BonusToken/>
                    </div> */}
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps, {FetchUser})(ManageTime);