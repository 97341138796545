import { connect } from "react-redux";
import React, { useState } from "react";
import Button from "../Components/Button";
import { RedeemTime, SetTimeDifference } from "../actions";
import { useConnect, useContractWrite } from "wagmi";
import MintConnectWallet from "./MintConnectWallet";
import breifcase from ".././breifcase.png";
import BriefcaseAbi from "../BriefcaseAbi";
import Time from "../Components/Time";
import MintAutomatonButton from "./SampleMint";
import { Link } from "react-router-dom";

const Mint = (props) => {
  const [minted, setMinted] = useState(false);

  const { isConnected } = useConnect();

  return (
    <div className={"flex flex-col items-center gap-y-8"}>
      {!isConnected && <MintConnectWallet />}

      {isConnected && (
        <>
          <div
            className={
              "relative flex items-center justify-center md:w-72 md:h-72 w-64 h-64 select-none"
            }
          >
            <img src={breifcase} className={`absolute w-full h-full`} />
          </div>
          <div className="flex flex-col">
            <h1 className="text-xl font-julius">Welcome!</h1>
            <h1 className="font-open font-thin w-96 p-2">
              ​ If this is your first time acquiring <Time/>, be sure to do so from
              a wallet you will continue to have access to, as you will have
              many decisions to make over the course of the <span className="text-time-light">Time Capsule.</span><br/><br/>
              Remember! Your Briefcase is not transferrable. Not to other
              individuals, and not to other wallets you might own without
              assistance. It is soulbound to the account it is minted to.<br/><br/>
              Briefcases can be minted until the Time Capsule closes for
              deposits at 5pm EST, Sunday July 10th.
            </h1>
          </div>
          {!minted ? (
            <MintAutomatonButton onMint={() => setMinted(true)} />
          ) : (
            <div className="flex gap-x-4">
              <Button onClick={() => window.open(process.env.REACT_APP_DESIGN_STUDIO)} >Design my briefcase</Button>

              <Link to="/">
                <Button>
                    Manage my <Time />
                </Button>
              </Link>

            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    main: state.Main,
  };
};

export default connect(mapStateToProps, { RedeemTime, SetTimeDifference })(
  Mint
);
