import Token from "../Components/Token";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux"
import Button from "../Components/Button";
import {SetAuthenticated} from "../actions";
import {useConnect} from "wagmi";
import breifcase from ".././breifcase.png"

const MintConnectWallet = (props) => {
    const {
        connect,
        connectors,
        error,
    } = useConnect()

    return (
        <div className={"flex flex-col gap-y-10 items-center"}>
            <div className={"relative flex items-center justify-center md:w-72 md:h-72 w-64 h-64 select-none"}>
                <img src={breifcase} className={`absolute w-full h-full`}/>
            </div>
            {!props.children && <h1 className={"font-julius md:text-2xl text-lg text-center"}>Enter the <span className={"text-time-light"}>Society of Time Travelers</span></h1>}
            
            {props.children && props.children}
            <div className={"flex flex-col gap-y-4"}>
                {connectors.map((connector) => (
                    connector.ready &&
                        <Button
                            disabled={!connector.ready}
                            key={connector.id}
                            onClick={() => connect(connector)}
                        >
                            {connector.name}
                            {!connector.ready && ' (unsupported)'}
                        </Button>
                ))}

                {error && <div>{error?.message ?? 'Failed to connect'}</div>}
            </div>
        </div>
    )
}


export default connect(undefined,{SetAuthenticated})(MintConnectWallet);