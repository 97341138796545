import InnerRing from "../innerRing.png"
import OuterRing from "../outerRing.png"
import React from "react";

const Token = (props) => {
    return (
        <div className={"relative flex items-center justify-center md:w-72 md:h-72 w-64 h-64 select-none"}>
            <h1 className={`text-${props.size} text-center title font-julius absolute ${props.subText && "pb-4"}`}>{props.children}</h1>
            <img src={InnerRing} className={`absolute w-full h-full ${props.spin && `animate-spin-slow`}`}/>
            <img src={OuterRing} className={"absolute w-full h-full"}/>
            <h1 className={`h1 absolute pt-12`}>{props.subText}</h1>
        </div>
    )
}

Token.defaultProps = {
    children: "",
    size: "timeToken",
    subText: "",
    spin: true,
}

export default Token