import { connect } from 'react-redux';
import { formatEther } from 'ethers/lib/utils';
import Time from './Time';

const TimeCalculation = (props) => {

    const {    
        oldTimeRedeemed,
        oldTimeBalance,
        haveDiscountApplied,
        totalNewlyAcquiredTime
    } = props.main

    const _totalNewlyAcquiredTime = totalNewlyAcquiredTime + props.acquireAmount;

    const oldTimeLeft = oldTimeBalance - oldTimeRedeemed;
    const newTimerAfterRollover = oldTimeLeft * 1.2;
    const rollOverBonus = newTimerAfterRollover * 0.05;
    const tokenBonus = haveDiscountApplied ? _totalNewlyAcquiredTime * 0.05 : 0;
    const totalTime = _totalNewlyAcquiredTime + newTimerAfterRollover + rollOverBonus + tokenBonus;

    const newUser = oldTimeBalance === 0 || !oldTimeBalance;

    const numberWidth = `md:w-32 w-20 text-right`

    return (
        <div className="flex flex-col divide-y gap-y-2 divide-time-gold md:text-2xl text-base">
            <div className="flex flex-col text-center md:w-96 w-64">
                {
                    !newUser &&
                    <>
                    <div className="flex justify-between">
                        <h1>Rollover {(oldTimeLeft).toFixed(2)} <Time/></h1>
                        <h1 className='flex-grow text-right '>=</h1>
                        <h1 className={numberWidth}>{(newTimerAfterRollover).toFixed(2)} <Time/></h1>
                    </div>

                    <div className="flex justify-between">
                        <h1>5% rollover bonus </h1>
                        <h1 className='flex-grow text-right'>=</h1>
                        <h1 className={numberWidth}>{(rollOverBonus).toFixed(2)} <Time/></h1>
                    </div>
                    </>
                }

                {!props.hideAcquire &&
                <>
                    <div className="flex justify-between">
                        <h1>Newly acquired <Time/> </h1> 
                        <h1 className='flex-grow text-right'>=</h1>
                        <h1 className={numberWidth}>{_totalNewlyAcquiredTime.toFixed(2)} <Time/></h1>
                    </div>

                    {haveDiscountApplied ? 
                    <div className="flex justify-between">
                        <h1>5% bonus token</h1> 
                        <h1 className='flex-grow text-right'>=</h1>
                        <h1 className={numberWidth}>{tokenBonus.toFixed(2)} <Time/></h1>
                    </div>
                    :
                    <div className="flex justify-between">
                        <h1>5% bonus token</h1> 
                        <h1 className='flex-grow text-right'>=</h1>
                        <h1 className={numberWidth}>Not Active</h1>
                    </div>
                    }
                </>
                }

            </div>  
            <div className="flex justify-between pt-2">
                <h1>Total <Time/></h1> 
                <h1 className='flex-grow text-right'>=</h1>
                <h1 className={numberWidth}>{totalTime.toFixed(2)} <Time/></h1>
            </div>
        </div>
    )
}

TimeCalculation.defaultProps = {
    acquireAmount: 0,
    hideAcquire: false,
    hideRollover: false,
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps)(TimeCalculation);