import React from 'react';
import ConnectWallet from "./views/ConnectWallet";
import Header from "./Components/Header";
import ManageTime from "./views/ManageTime";
import {connect} from "react-redux"

import {
    Routes,
    Route,
    BrowserRouter
} from "react-router-dom";
import RollOver from "./views/RollOver";
import Acquire from "./views/Acquire";
import Redeem from "./views/Redeem";
import {useConnect} from "wagmi";
import Redeemed from "./views/Redeemed";
import Acquired from "./views/Acquired";
import SignToVerify from "./views/SignToVerify";
import NoTime from "./views/NoTime";
import {Navigate} from "react-router";
import Mint from './views/Mint';

//waaah
const App = (props) => {
    const {
        isConnected,
      } = useConnect()

  return (
      <BrowserRouter>
        <div className="text-center p-4 App flex items-center justify-between w-screen h-screen flex-col text-time-gold">
          <Header/>
            {
                isConnected ?
                (props.main.authenticated ?
                    <Routes>
                        <Route path={"/"} element={<ManageTime/>}/>
                        <Route path={"/manage"} element={<ManageTime/>}/>
                        <Route path={"/rollover"} element={<RollOver/>}/>
                        <Route path={"/acquire"} element={<Acquire/>}/>
                        <Route path={"/acquired"} element={<Acquired/>}/>
                        <Route path={"/redeem"} element={<Redeem/>}/>
                        <Route path={"/redeemed"} element={<Redeemed/>}/>
                        <Route path={"/mint"} element={<NoTime/>}/>
                        <Route path={"*"} element={<Navigate to="/" />} />
                    </Routes>
                    :
                    <Routes>
                        <Route path={"/noTime"} element={<NoTime/>}/>
                        <Route path={"/"} element={<SignToVerify/>}/>
                        <Route path={"/mint"} element={<NoTime/>}/>
                        <Route path={"*"} element={<Navigate to="/" />} />
                    </Routes>
                    )
                :
                <Routes>
                    <Route path={"/mint"} element={<NoTime/>}/>
                    <Route path={"/connect"} element={<ConnectWallet/>}/>
                    <Route path={"*"} element={<ConnectWallet/>} />
                </Routes>
            }

          <div></div>
        </div>
      </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps)(App);
