import {connect} from "react-redux"
import Time from "../Components/Time";
import { formatEther } from 'ethers/lib/utils';
import { parseEther } from 'ethers/lib/utils';
import ReturnToDashboard from './../Components/ReturnToDashboard';

const Acquired = (props) => {
    const {    
        oldTimeRedeemed,
        oldTimeBalance,
        haveDiscountApplied,
        totalNewlyAcquiredTime
    } = props.main

    const _totalNewlyAcquiredTime = totalNewlyAcquiredTime + parseFloat(formatEther(props.main.timeDifference));

    const oldTimeLeft = oldTimeBalance - oldTimeRedeemed;
    const newTimerAfterRollover = oldTimeLeft * 1.2;
    const rollOverBonus = newTimerAfterRollover * 0.05;
    const tokenBonus = haveDiscountApplied ? _totalNewlyAcquiredTime * 0.05 : 0;
    const totalTime = _totalNewlyAcquiredTime + newTimerAfterRollover + rollOverBonus + tokenBonus;


    return(
        <div className={"flex flex-col font-open font-thin items-center gap-y-6"}>
            <h1 className={"title md:text-4xl text-2xl text-center"}>
                {formatEther(props.main.timeDifference)} Time has been successfully acquired!
            </h1>
            <div className={"flex flex-col items-center gap-y-1"}>
                <h1 className={"md:text-3xl text-2xl"}>
                    Your balance will be updated to {totalTime.toFixed(2)} <Time/> shortly
                </h1>
            </div>
            <ReturnToDashboard/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps)(Acquired);