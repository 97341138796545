import axios from "axios"
import {DISCOUNT_REDEEMED, CHANGE_TIME_DIFFERENCE, REDEEM_TIME, SET_AUTHENTICATED, SET_TIME_DIFFERENCE, SET_USER, GOT_BRIEFCASE} from "./types";
import { formatEther } from 'ethers/lib/utils';
import { parseEther } from 'ethers/lib/utils';

const timeApi = axios.create({
    baseURL: process.env.REACT_APP_TIME_API
})
//wah

export const ChangeTimeDifference = (time) => {
    return {
        type: CHANGE_TIME_DIFFERENCE,
        payload: {
            time
        }
    }
}

export const SetTimeDifference = (time) => {
    return {
        type: SET_TIME_DIFFERENCE,
        payload: {
            time
        }
    }
}

export const GotBriefcase = () => {
    return {
        type: GOT_BRIEFCASE,
    }
}

export const SetAuthenticated = (connectedWallet, authToken) => {
    return {
        type: SET_AUTHENTICATED,
        payload: {
            authenticated: true,
            connectedWallet,
            authToken,
        }
    }
}

export const FetchUser = () => async (dispatch, getState) => {
    const {connectedWallet, authToken} = getState().Main;

    const res = await timeApi.get(`/api/getTimeQuantity?address=${connectedWallet}&token=${authToken}`)
    const {data} = res;
    
    dispatch({
        type: SET_USER,
        payload: data
    })
}

export const RedeemTime = (navigate, signingMessage, signature, timeQuantity) => async (dispatch, getState) => {
    const {timeDifference, authToken, connectedWallet} = getState().Main;

    const amount = formatEther(timeDifference);

    try{
        const res = await timeApi.post(`/api/updateQuantity`, {
            address: connectedWallet,
            token: authToken,
            amount: parseFloat(amount),
        })
        
        const {data} = res;
    
        await dispatch({
            type: SET_USER,
            payload: data
        })

        navigate("/redeemed")
    }
    catch (e){
        console.log("Failed to redeem Time")
        console.log(e)
    }
}

export const RollOver = (navigate) => async (dispatch, getState) => {
    const { authToken, connectedWallet} = getState().Main;

    try{
        const res = await timeApi.post(`/api/updateQuantity`, {
            address: connectedWallet,
            token: authToken,
            rollover: true,
        })

        const {data} = res;
    
        await dispatch({
            type: SET_USER,
            payload: data
        })

        if(navigate)
            navigate("/")
    }
    catch (e){
        console.log("Failed to redeem Time :(")
        console.log(e)
    }
}

export const DiscountRedeemed = () => {
    return {
        type: DISCOUNT_REDEEMED,
    }
}