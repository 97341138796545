import React from "react";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <Link to={"/"}>
            <div className={"flex flex-col pt-8 items-center gap-y-3 w-screen"}>
                <h1 className={"text-4xl title font-julius font-thin"}>TIME VAULT</h1>
                <div className={"line md:w-96 w-3/4"}/>
            </div>
        </Link>

    )
}

export default Header;