import { useEffect, useState } from "react";
import { useContractWrite } from "wagmi";
import whitelist from "../whitelist";
import ABI2 from "../abi";
import { utils, ethers, providers } from "ethers";
import Button from "../Components/Button";
import { useAccount } from "wagmi";
import { connect } from 'react-redux';
import { GotBriefcase } from './../actions';

const options = {
    //provider is localhost:8545
    provider: providers.getDefaultProvider()
};
  
const contractAddress = "0xeab1a3126113adfb452f7fb2e4f310ebe11373e2";

const MintAutomatonButton = props => {
  const account = useAccount();
  const [Salestate, updateSalestate] = useState(false);
  function getNonceAndSignature(address) {
    if (!address && Salestate) {
      return { nonce: 1, signature: 0x7b, whitelisted: false };
    } else if (Salestate) {
      const users = Object.entries(whitelist);
      const user = users.find(
        ([key, value]) =>
          utils.getAddress(key?.toLowerCase()) ===
          utils.getAddress(address?.toLowerCase())
      );
      if (user === undefined) {
        return { nonce: 1, signature: 0x7b, whitelisted: false };
      } else {
        if (user) {
          return {
            nonce: user[1].allowance,
            signature: user[1].signature,
            whitelisted: true,
          };
        }
      }
    } else if (!Salestate) {
      return { nonce: null, signature: null, whitelisted: false };
      // return { nonce: '1', signature: 0x7b, whitelisted: true }
    }
    return { nonce: null, signature: null, whitelisted: false };
  }

  const { nonce, signature, whitelisted } = getNonceAndSignature(
    account.data?.address
  );

  async function checkSaleState() {
    const sState = new ethers.Contract(
      contractAddress,
      ABI2,
      options.provider
    );
    const salestate = await sState.checkStorage();
    //salestate is an array of 3 elements, the first one is the lock bool, second is the salestate bool, third one is price
    return salestate[1];
  }

  const {
    data: wldata,
    isError: wlisError,
    isLoading: wlisLoading,
    writeAsync: wlwrite,
    status: wlstatus,
    error: wlerror,
  } = useContractWrite(
    {
      addressOrName: contractAddress,
      contractInterface: ABI2,
    },
    "TimeTravelerMint",
    {
      args: [true, nonce, signature],
    }
  );

  const {
    data: nowldata,
    isError: nowlisError,
    isLoading: nowlisLoading,
    writeAsync: nowlwrite,
    status: nowlstatus,
    error: nowlerror,
  } = useContractWrite(
    {
      addressOrName: contractAddress,
      contractInterface: ABI2,
    },
    "TimeTravelerMint",
    {
      args: [false, nonce, signature],
      overrides: {
        value: ethers.utils.parseEther("0.1"),
      },
    }
  );

  const mintMessages = {
    idle: `Mint briefcase (${whitelisted ? "FREE" : "0.1 ETH" })`,
    loading: "Confirm transaction",
    error: nowlerror?.message?.includes("exceeds balance")
      ? "Insufficient funds"
      : "Transaction cancelled" || wlerror?.message?.includes("User denied transaction")
      ? "Transaction cancelled"
      : "Transaction cancelled" || nowlerror?.message?.includes("User denied transaction")
      ? "Transaction cancelled"
      : "Transaction cancelled",
    success: "Check your wallet",
  };

  // const { data: trx, isError: trxError, isLoading:trxLoading, status: trxstatus } = useWaitForTransaction({
  //     wait: data?.wait,
  //   })

  useEffect(() => {
    if (account.data?.address) {
      checkSaleState().then((result) => {
        updateSalestate(result);
      });
    }
  }, [account.data?.address]);

  const mint = async () => {
    if (whitelisted) {
      console.log("Minting whitelist mint")
      const tx = await wlwrite();
    } else {
      console.log("Minting without whitelist")
        const tx = await nowlwrite();
    }

    props.GotBriefcase();
    props.onMint();
  };

  return (
    account.data?.address && (
      <Button
        loading={false}
        size={2}
        onClick={mint}
        enabled={Salestate.toString()}
        className="flex text-4xl border-6 invertedMintButton text-black hover:text-white hover:bg-black p-2"
      >
        {Salestate
          ? nowlerror?.message?.includes("Already minted")
            ? nowlerror?.message?.includes("exceeds balance")
              ? wlerror?.message?.includes("Already minted")
                ? wlerror?.message?.includes("exceeds balance")
                  ? "INSUFFICIENT FUNDS"
                  : "ALREADY MINTED"
                : mintMessages[wlstatus]
              : "MINT"
            : mintMessages[wlstatus]
          : mintMessages[wlstatus]}
      </Button>
    )
  );
};

export default connect(undefined, {GotBriefcase})(MintAutomatonButton);
