import React from "react";
import Time from "../Components/Time";
import Button from "../Components/Button";
import {useAccount, useDisconnect} from "wagmi";
import {useNavigate} from "react-router-dom";

const NoTime = () => {
    const {disconnect} = useDisconnect();
    const navigate = useNavigate();

    const onDisconnect = () => {
        disconnect();
        navigate("/")
    }

    return(
        <div className={"flex flex-col text-center gap-y-12"}>
            <div className={"flex flex-col gap-y-6"}>
                <h1 className={"font-julius md:text-3xl text-3xl"}>
                    Sorry, this wallet is not currently part of the <span className={"text-time-light"}>Time Traveler Society.</span>
                </h1>
                <h1 className={"font-julius md:text-3xl text-3xl"}>
                Please connect with a wallet that holds a <span className={"text-time-light"}>Briefcase</span> to continue. 
                </h1>
            </div>
            <div className="div flex gap-x-2 justify-center">
                <Button onClick={onDisconnect}>Disconnect wallet</Button>
                <a href={"http://about.whatistime.art/"}>
                            <Button>Read more</Button>
                    </a>
            </div>

        </div>
    )
}

export default NoTime;