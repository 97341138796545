import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import TimeInput from "../Components/TimeInput";
import Button from "../Components/Button";
import {useAccount, useBalance, useContractWrite, useSigner, useSendTransaction} from 'wagmi'
import {BigNumber} from "@ethersproject/bignumber/lib.esm/bignumber";
import {MdRefresh} from "react-icons/md"
import {SetTimeDifference, RollOver} from "../actions";
import {useNavigate} from "react-router-dom";

import { formatEther } from 'ethers/lib/utils';
import Time from "../Components/Time";
import TimeCalculation from "../Components/TimeCalculation";
import BonusToken from './../Components/BonusToken';



const Acquire = (props) => {

    const navigate = useNavigate();
    const timeValue = parseInt(process.env.REACT_APP_ACQUIRE_PRICE);
    
    const account = useAccount()
    const {data: balanceData} = useBalance({
        addressOrName: account.data.address
    })


    useEffect(() => {
        props.SetTimeDifference(0)
    }, [])

    const { data: transactionData, sendTransactionAsync, isLoading } = useSendTransaction({
        request: {
            to: '0x334e503aec46d57e1e2b3b4f754f387895f0197c',
            value: BigNumber.from(props.main.timeDifference).div(1000).mul(timeValue), // 1 ETH
        },
    })

    const acquireTime = async () => {
        await sendTransactionAsync()
        await props.RollOver();
        navigate("/acquired")
    }

    return (
        <div className={"flex flex-col items-center gap-y-10 font-open font-thin"}>
            <h1 className="text-3xl">Acquire <Time/></h1>
            <TimeCalculation acquireAmount={parseFloat(formatEther(props.main.timeDifference))}/>

            <h1 className={"md:text-2xl text-xl text-center"}>How much <span className={"text-time-light"}>Time</span> do you wish to acquire?</h1>
            <TimeInput/>
            <div className={"flex flex-col items-center gap-y-2"}>
                <div className="flex flex-col">
                    <h1>{balanceData?.value?.lt(BigNumber.from(props.main.timeDifference).div(1000).mul(timeValue)) && `Insufficient balance`}&nbsp;</h1>
                </div>
                <div className="flex flex-col gap-y-4 justify-center items-center">
                    <Button onClick={acquireTime}>
                        <div className={"flex items-center gap-x-2"}>
                            <h1>
                                Acquire {formatEther(props.main.timeDifference)} <span className={"text-time-light"}>Time</span> for {formatEther(BigNumber.from(props.main.timeDifference).div(1000).mul(timeValue))} ETH
                            </h1>
                            {isLoading && <MdRefresh className={"animate-spin"}/>}
                        </div>

                    </Button>
                    <BonusToken/>
                </div>

            </div>

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        main: state.Main
    }
}

export default connect(mapStateToProps, {SetTimeDifference, RollOver})(Acquire);